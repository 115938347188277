/*
App
Created at 09/04/2021 11:44
Author: Khaliq ALI
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import install from './install'
import firebase from 'firebase'
import i18n from './i18n'
import { firestorePlugin } from 'vuefire'
import VueCountryCode from 'vue-country-code'

Vue.use(VueCountryCode)
Vue.use(install)
Vue.use(firestorePlugin)
Vue.use(VueCountryCode)

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = process.env.NODE_ENV !== 'production'
let app = ''

firebase.initializeApp({
  apiKey: 'AIzaSyB2iAphQyC09WOzAz56pzb8MLdLu_ndikU',
  authDomain: 'nolido-630ae.firebaseapp.com',
  projectId: 'nolido-630ae',
  storageBucket: 'nolido-630ae.appspot.com',
  messagingSenderId: '1021425895541',
  appId: '1:1021425895541:web:3e8234808a1e1ea019503c',
  measurementId: 'G-0FBM8E36WR'
})
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})
export const db = firebase.firestore()
export const storage = firebase.storage()
