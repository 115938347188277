
import Vue from 'vue'
import VueRouter from 'vue-router'
import pathResolver from '@/plugins/loader'
import firebase from 'firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/sign',
    name: 'SignUp',
    component: pathResolver('sign', 'index')
  },
  {
    path: '/login',
    name: 'Login',
    component: pathResolver('login', 'index')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: pathResolver('dashboard', 'index'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: pathResolver('about', 'index')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // retourner la position désirée
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requireAuth = to.matched.some(record => record.meta.requireAuth)

  if (requireAuth && !currentUser) next('login')
  else if (!requireAuth && currentUser) next('/')
  else next()
})

export default router
